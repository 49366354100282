@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .dark .dark-border {
    @apply border border-gray-700;
  }

  .light-border {
    @apply border border-gray-300;
  }
}

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-2xl;
}
h4 {
  @apply text-xl;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-sm;
}
a {
  @apply text-blue-600 underline;
}
ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

table {
  @apply table-auto;
}
.darkSecondary {
  @apply bg-gray-700;
}
.h-screen-80 {
  height: calc(100vh - 80px);
}
.selectedlink {
  color: #bd00ff;
  text-decoration: none;
}
.react-tiny-popover-container {
  top: 100px;
}
.navbar-active-item {
  border: solid;
  border-width: 0px;
  border-bottom-width: 3px;
  border-color: #0ea5e9;
  padding-bottom: 2px;
  transition: all ease-in-out 50ms;
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #6b7280 !important;
}

.ql-toolbar .ql-fill {
  fill: #6b7280 !important;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #6b7280 !important;
}

.landing-bg {
  background: rgb(53, 12, 86);
  background: linear-gradient(
    90deg,
    #270a4b 25%,
    #1b0843 50%,
    #16032f 75%,
    #160033 100%
  );
}
.landing-secsix {
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  background: rgb(45, 1, 102);
  background: linear-gradient(
    180deg,
    rgba(45, 1, 102, 1) 11%,
    rgba(29, 0, 67, 1) 36%,
    rgba(14, 0, 33, 1) 100%
  );
}

.edit-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #d9d9d9;
}
.wordwrap {
  white-space: pre-wrap; /* Webkit */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.dark .edit-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #525252;
}
.edit-scrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #f6f3f3;
}
.dark .edit-scrollbar::-webkit-scrollbar-thumb {
  background-color: #525252;
  width: 20px;
  height: 40px;
  border-radius: 30px;
}
.no-dots .slick-dots {
  display: none !important;
}
.slick-dots > li > button {
  height: 15px !important ;
  width: 15px !important;
  clip-path: circle();
  background: #441e83 !important;
}
.slick-dots > .slick-active > button {
  height: 20px !important ;
  width: 20px !important;
  clip-path: circle();
  background: #8c49fe !important;
}
.slick-dots li button:before {
  font-size: 0px !important;
}

.edit-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ededed;
  width: 20px;
  height: 40px;
  border-radius: 30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.usecase::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.usecase {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*________________________
____________________________________ */


.flex {
  display: flex;
}

.grid {
  display: grid;
}

.gap {
  column-gap: 1rem;
  row-gap: 1rem;
}

.common-container {
  max-width: 82rem;
  margin: 0 auto;
}

.grid-three-col {
  grid-template-columns: repeat(3, 1fr);

}

.grid-two-col {
  grid-template-columns: repeat(2, 1fr);
}

/* ________________________________ 
Our Why -Dex Auction CSS code starts here 
-------------------------- */

.why-dex-auction-comp {
  position: relative;
  width: 100%;
  background-color: #efefef;
  padding: 2.5rem 1.87rem;

}

.why-dex-auction-flex-top-flex-bx {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

}

.why-dex-auction-label-bx {
  position: relative;
  width: max-content;
  padding: .8rem 1rem;
  border: .1rem solid #5300CC;
  border-radius: 4.8rem;
  background-color: transparent;
  transition: 500ms ease;


}

.why-dex-auction-label-bx:hover{
  background-color: #5300CC;
}

.why-dex-auction-label-bx:hover span{
  color: #fff;

}

.market-size-text:hover{
  background-color: #fff;
}

.market-size-text:hover span{
  color: #5300CC;
}


.why-dex-auction-label-bx span {
  display: inline-block;
  width: max-content;
  color: #5300CC;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  /* 32.4px */
  text-transform: uppercase;
}

.right-why-dexauction-title-bx {
  position: relative;
  max-width: 50rem;
}

.right-why-dexauction-title-bx h3 {
  color: #110F42;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  /* 75.6px */
  letter-spacing: -1.12px;
}

.why-dexauction-cards-grid {
  position: relative;
  width: 100%;
  margin-top: 4rem;

}

.why-dexauction-bx {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: .65rem;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5rem;
  overflow: hidden;
  transition: 500ms ease;

}

.why-dexauction-bx:hover {
  transform: translateY(-30px);
  box-shadow: rgba(255, 255, 255, 0.473) 0px 1px 1px 0px inset, rgba(198, 198, 199, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.why-dexauction-img {
  position: relative;
  width: 8rem;
}

.why-dexauction-img img {
  width: 100%;
  height: auto;
}

.why-dexauction-info h5 {
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: .45px;
  margin-bottom: .65rem;
  color: #110F42;

}

.why-dexauction-info p {
  color: #6A6D77;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
}

.svg-bx {
  position: absolute;
  bottom: 0;
  right: 0;
}

.svg-bx svg {
  width: 12rem;
  height: 12rem;
}

/* ________________________________ 
Our Why -Dex Auction CSS code end here 
-------------------------- */


/* ________________________________ 
Our how it work CSS code starts here 
-------------------------- */

.how-it-work-comp {
  position: relative;
  width: 100%;
  padding: 3rem 1.87rem;
  z-index: 1000;
  background-color: #fff;
}



.hw-it-wrk-flex-main {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90rem;
  margin: 1rem auto 0 auto;
}

.hw-it-wrk-flex-main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 40%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: url('../public/images/bannerbg.png')no-repeat center center/cover;
  z-index: -1;
}

.left-hw-it-wrk-bx {
  position: relative;
}

.left-hw-it-wrk-bx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.center-hiw-flex-bx {
  position: relative;
  height: 645px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.center-hiw-bx {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;

}

.center-hiw-round-bx {
  position: relative;
  display: flex;
  width: 200px;
  height: 200px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-radius: 240px;
  border: 1px solid #E4E4E4;
  background: #FEFFFF;

}

.center-hiw-round-bx img {
  width: 4rem;
}

.center-hiw-round-bx h6 {
  font-size: 1.1rem;
  font-weight: 700;
  color: #110F42;
  line-height: 1.2;

}

.center-hiw-bx p {
  color: #5300CC;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  max-width: 20rem;
}

.right-hiw-main-flex {
  position: relative;
  padding: 1.5rem 1.5rem;
  background-color: #feffff;
  border: .1rem solid #e4e4e4;
  border-radius: .87rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}

.hiw-comn-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
}

.hiw-comn-bx {
  position: relative;
  width: 15rem;
  padding: 1.5rem 1.5rem;
  background-color: #fff;
  border: .1rem solid #e4e4e4;
  border-radius: .76rem;

}


.hiw-comn-bx p {
  color: #110F42;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  /* 27px */
  max-width: 15rem;
  margin-bottom: 0;
}

.hiw-comn-main h6 {
  color: #5300CC;

  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  /* 125% */
  max-width: 10rem;

}

.hiw-comn-img {
  width: 6rem;
  margin: 0 auto;
}

.hiw-comn-img img {
  width: 100%;
  height: auto;
}

.element-arow-bx {
  position: absolute;
  top: 8rem;
  left: 15rem;
  width: 11rem;
  animation: movearrow 1s linear infinite alternate;
}



.element-arow-bx2 {
  position: absolute;
  top: 5rem;
  left: 47rem;
  width: 11rem;
  animation: movearrow 1s linear infinite alternate;

}

@keyframes movearrow {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(20px);
  }
}

.element-arow-bx3 {
  position: absolute;
  top: 50%;
  left: 97%;
  width: 4.5rem;
  transform: translateY(-50%);
  animation: movearrowblock 1s linear infinite alternate;

}

@keyframes movearrowblock {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(-40%);
  }
}

.element-arow-bx4 {
  position: absolute;
  bottom: 8rem;
  left: 49rem;
  width: 11rem;
  animation: movearrowinline 1s linear infinite alternate;

}

.element-arow-bx5 {
  position: absolute;
  bottom: 8rem;
  left: 17rem;
  width: 13rem;
  animation: movearrowinline 1s linear infinite alternate;

}


@keyframes movearrowinline {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-20px);
  }
}

.element-arow-bx img,
.element-arow-bx2 img,
.element-arow-bx3 img,
.element-arow-bx4 img,
.element-arow-bx5 img {
  width: 100%;
}

/* ________________________________ 
Our how it work CSS code end here 
-------------------------- */

/* ________________________________ 
Our Market Size CSS code starts here 
-------------------------- */

.market-size-comp {
  position: relative;
  width: 100%;
  padding: 3rem 1.87rem;
  background-color: #5300CC;

}

.market-size-text {
  border-color: #fff;
  margin-bottom: 2.5rem;
}

.market-size-text span {
  color: #fff;
  font-weight: 500;
}

.global-market-size-info-flex-bx {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

}

.global-market-size-info-flex-bx h3 {
  color: #FFF;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 135%;
  /* 75.6px */
  letter-spacing: -1.12px;
  max-width: 20rem;
}

.global-market-size-info-flex-bx p {
  max-width: 35rem;
  color: rgba(255, 255, 255, 0.90);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.7;
  /* 27px */
}


.sale-volume-main-bx {
  position: relative;
  width: 100%;
  margin-top: 7rem;
  /* z-index: 100; */

}

.globe-img {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  right: .87rem;
  width: 550px;
  height: 520px;
  background: url('../public/images/Layer-2.png')no-repeat center center/cover;
}

/* .market-size-cont{
  max-width: 75rem;
} */

/* .globe-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.token-bg-bx{
  background-color: transparent !important;
}

.token-check-bg{
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.token-check-bg:before{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  width: 100%;
  height: 150%;
  background: url('../public/images/rndmainbg.png')no-repeat center center/cover;
  z-index: -1;
}

.sale-valume-text-bx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-bottom: 5rem;
}

.sale-valume-text-bx h6 {
  color: #FFF;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: .55px;
  line-height: 120%;
  /* 38.4px */
  text-transform: uppercase;
  margin-bottom: .78rem;
}

.sale-valume-text-bx span {
  color: #FFF;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .55px;
  line-height: 120%;
  /* 21.6px */
}

.sales-volme-grid {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.sales-volume-bx {
  position: relative;
  width: 100%;
  background-color: #8850EB;
  border-radius: .8rem;
  padding: 1.2rem 1.5rem;

}

.sales-volume-bx p {
  color: rgba(255, 255, 255, 0.90);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 120%;
  /* 21.6px */
  text-transform: capitalize;
}

.sales-volume-bx h5 {
  color: #FFF;
  text-align: right;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: .55px;
  line-height: 120%;
  /* 76.8px */
  margin-top: 3rem;
}

/* ________________________________ 
Our Market Size CSS code end here 
-------------------------- */


/* ________________________________ 
Our contact comp CSS code starts here 
-------------------------- */

.contact-comp {
  position: relative;
  width: 100%;
  padding: 6rem 1.87rem;

}

.contact-text-flex-bx {
  margin-top: 5rem;
}

.contact-text-flex-bx{
  align-items: flex-start;
}

.contact-text-flex-bx h3 {
  color: #110F42;
  max-width: 30rem;
}

.contact-text-flex-bx p {
  color: #6A6D77;

}


.contact-grid {
  position: relative;
  width: 100%;
  margin-top: 5rem;
}

.left-contact-info {
  position: relative;
  width: 100%;
}

.cont-det-bx {
  margin-bottom: 5rem;
}

.cont-det-bx span,
.right-cont-form span {
  color: #6A6D77;
  font-size: 13px;
  font-weight: 400;
  line-height: 135%;
  /* 17.55px */
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: inline-block;
}

.cont-det-bx p {
  color: #110F42;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  /* 21.6px */
}

.cont-det-bx>p~p {
  max-width: 27rem;
  margin-top: 1rem;
}

.right-cont-form {
  position: relative;
  width: 100%;
}

.right-cont-form span {
  margin-bottom: 1.5rem;
}

.cont-inpt-bx {
  position: relative;
  width: 100%;
  height: 4rem;
}

.cont-inpt-bx input {
  color: #6A6D77;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  border-bottom: .1rem solid #ddd;
  transition: 400ms ease;

}



.cont-mesage-bx textarea {
  width: 100%;
  padding: .5rem 0;
  color: #6A6D77;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: .1rem solid #ddd;
  resize: none;
  transition: 400ms ease;

}

.cont-mesage-bx textarea {
  padding-top: 1.5rem;
}

.cont-inpt-bx input:focus,
.cont-mesage-bx textarea:focus {
  outline: none;
}

.cont-inpt-bx input:focus ,
.cont-mesage-bx textarea:focus{
  border-bottom-color: #5300CC;
}

.form-check-bx {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}

.form-check-bx input {
  width: 2rem;
  height: 2rem;

}

.form-check-bx input:focus {
  box-shadow: none;
  border-color: #ddd;
}

.form-check-bx input:checked {
  background-color: #5300CC;
  border-color: #5300CC;
}


.form-check-bx span {
  color: #6A6D77;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
}

.cont-form-btn {
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .65rem 1.5rem;
  border-radius: .8rem;
  background-color: #5300CC;
  color: #fff;
  font-size: 1rem;
  border: none;
  margin-top: 1rem;
  transition: 400ms ease;
  border: .1rem solid #5300CC;
}

.cont-form-btn i{
  transition: 400ms ease;
}

.cont-form-btn:hover{
}

.cont-form-btn:hover i{
  transform: translateX(10px);
}



/* ________________________________ 
Our contact comp CSS code end here 
-------------------------- */


/* ________________________________ 
Our Dexa Token CSS code Starts here 
-------------------------- */

.dexa-token-comp {
  position: relative;
  width: 100%;
  padding: 3rem 1.87rem;
  background-color: #fff;

}

.dexa-token-info-flex {
  align-items: flex-start;
}

.dexa-token-info-flex h3 {
  max-width: 60rem;
}

.dexa-token-info-flex h3 span {
  color: #5300CC;
}

.dexa-token-info-flex P {
  margin-bottom: 0;
  margin-top: 0;

}


.token-img {
  position: relative;
  width: 60%;
  margin: 0 auto 0 auto;
  padding: 1rem 0 5rem 0;
  z-index: 3;
}


.token-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 35%;
  transform: translateX(-50%) scale(1);
  width: 100%;
  height: 100%;
  background: url('../public/images/banner-bgg.png')no-repeat center center/cover;
  z-index: -1;
}

.token-img img {
  width: 100%;
  height: auto;
}


.dexa-token-comp .last-center-text {
  color: #110F42;

  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 45rem;
  margin: 0 auto;
  line-height: 1.67;
}


/* ________________________________ 
Our Dexa Token CSS code end here 
-------------------------- */

/* ______________________________
 Our Token Component CSS code here 
 -------------------- */

 .token-comp{
  position: relative;
  width: 100%;
  padding-block: 17rem;
  padding-inline: 1.87rem;
  background-color: #5300CC;
  overflow: hidden;
}

.token-comp-info{
position: relative;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
}

.token-comp-info h3{
font-size: 4rem;
font-weight: 600;
line-height: 1.3;
max-width: 55rem;
color: #fff;
}


.token-comp-info h5{
font-size: 2.9rem;
margin-top: 1rem;
font-weight: 600;
color: #a161ff;
}

.token-btn{
position: relative;
margin: 0 auto;
display: flex;
align-items: center;
justify-content: center;
margin-top: 3.5rem;
width: max-content;
padding: .67rem 1.87rem;
background-color: #fff;
border-radius: .45rem;
color: #441e83;
font-weight: 500;
transition: 400ms ease;
}

.token-btn:hover{
transform: translateY(-5px);
}

.dexa-text-img{
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: 90%;
}

.dexa-text-img img{
width: 100%;
height: auto;
}

.bottom-token-flex-bx{
position: absolute; 
top: 100%;
left: 50%;
transform: translateX(-50%) translateY(-100%);
display: flex;
align-items: center;
justify-content: space-between;
gap:25rem;
}

.token-img-bx{
position: relative;
width: 14rem;


}

.left-token-img-flex{
display: flex;
align-items: center;
}

.token-img-bx1{
transform: translateY(5px);
}

.token-img-bx2{
width: 7.5rem;
transform: translateX(-15px) translateY(25px);
}

.token-img-bx img{
width: 100%;
height: auto;
}

.right-token-img-flex{
display: flex;
align-items: center;
}

.token-img-bx3{
width: 13rem;
}

.token-img-bx4{
width: 9rem;
transform: translateX(-22px) translateY(26px);

}

.aisup-bg{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50% , -50%);
width: 100%;
height: 100%;
}

/* ______________________________
 Our Token Component CSS code here 
 -------------------- */

 .token-comp{
  position: relative;
  width: 100%;
  padding-block: 17rem;
  padding-block-end: 10rem;
  padding-inline: 1.87rem;
  background-color: #5300CC;
  overflow: hidden;
}

.token-comp-info{
position: relative;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
z-index: 5;
}

.token-comp-info h3{
font-size: 2.87rem;
font-weight: 600;
line-height: 1.3;
max-width: 40rem;
color: #fff;
}


.token-comp-info h5{
font-size: 2.9rem;
margin-top: 1rem;
font-weight: 600;
color: #a161ff;
}

.token-btn{
position: relative;
margin: 0 auto;
display: flex;
align-items: center;
justify-content: center;
margin-top: 3.5rem;
width: max-content;
padding: .67rem 1.87rem;
background-color: #fff;
border-radius: .45rem;
color: #441e83;
font-weight: 500;
transition: 400ms ease;
z-index: 5;
}

.token-btn:hover{
transform: translateY(-5px);
}

.dexa-text-img{
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: 90%;
z-index: 5;
}

.dexa-text-img img{
width: 100%;
height: auto;
}

.bottom-token-flex-bx{
position: absolute; 
top: 100%;
left: 50%;
transform: translateX(-50%) translateY(-100%);
display: flex;
align-items: center;
justify-content: space-between;
gap:25rem;
z-index: 7;
}

.token-img-bx{
position: relative;
width: 14rem;


}

.left-token-img-flex{
display: flex;
align-items: center;
}

.token-img-bx1{
transform: translateY(5px);
}

.token-img-bx2{
width: 7.5rem;
transform: translateX(-15px) translateY(25px);
}

.token-img-bx img{
width: 100%;
height: auto;
}

.right-token-img-flex{
display: flex;
align-items: center;
}

.token-img-bx3{
width: 13rem;
}

.token-img-bx4{
width: 9rem;
transform: translateX(-22px) translateY(26px);

}

.aisup-bg{
position: absolute;
top: 50%;
left: 45%;
transform: translate(-50% , -50%);
width: 80%;
height: 100%;
background: url('../public/images/Hero\ background.svg')no-repeat center center/cover;
z-index: 1;
}


.token-img2{
  margin: 2rem auto 0 auto;
}

/* ________________________________ 
Our Token Component CSS code end here 
-------------------------- */
/* ________________________________ 
Our Get your worth  CSS code starts here 
-------------------------- */

.get-your-worth-comp{
  padding-block: 3rem;
}

.get-yr-wrth-flex {
  align-items: flex-start;
}

.text-img-bx-main {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding-block: 5rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text-img-bx-main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 35%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: url('../public/images/banner-bgg-22.png')no-repeat center center/cover;
  z-index: -1;
}

.text-img-bx{
  width: 25rem;
}

.text-img-bx img {
  width: 100%;
  height: auto;
}


.text-img-bx1{
  position: relative;
  left: 2.5rem;
  top: 1rem;
  z-index: 5;
  animation: lefttoright 0.87s cubic-bezier(0.45, 0, 0.55, 1.5) infinite alternate;
}

.text-img-bx2{
  position: relative;
  width: 30rem;
  left: 0;
  z-index: 3;
  animation: toptotbotm 0.87s cubic-bezier(0.45, 0, 0.55, 1.5)  infinite alternate;

}


.text-img-bx3{
  position: relative;
  top: 1rem;
  width: 38rem;
  left: -1.87rem;
  z-index: 6;
  animation: righttoleft 0.87s cubic-bezier(0.45, 0, 0.55, 1.5)  infinite alternate;

}

@keyframes righttoleft {

  0%{
    transform: translateX(10px) rotate(5deg);
  } 

  100%{
    transform: translateX(0) rotate(0deg);
  }
  
}


@keyframes toptotbotm {

  0%{
    transform: translateY(-10px);
  }

  100%{
    transform: translateY(0);
  }
  
}

@keyframes lefttoright {

  0%{
    transform: translateX(-10px) rotate(-5deg);
  }

  100%{
    transform: translateX(0) rotate(0deg);
  }
  
}



.going-once-text h3{
  max-width: 40rem;
}

.going-img-flex{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.going-once-img1 {
  position: relative;
  left: 2rem;
  width: 35rem ;
  z-index: 3;
}

.going-once-img2{
  position: absolute;
  bottom: 1.87rem;
  left: 3rem;
  width: 40rem ;
  z-index: 5;

}

.going-once-img1{

  animation: goingonce 1s cubic-bezier(0.45, 0, 0.55, 1)  infinite alternate;
}

.going-once-img2{
  transform-origin: left center;
  animation: goingtwice 1s cubic-bezier(0.45, 0, 0.55, 1)  infinite alternate;
}

.going-once-img3{
  position: absolute;
  bottom: 2rem;
  right: -6rem;
  width: 15rem ;
  z-index: 6;
  transform-origin: center center;
  animation: bellrotating 1s cubic-bezier(0.45, 0, 0.55, 1)  infinite alternate;
}

@keyframes goingonce {

  0%{
    transform: translateX(5px) ;
  }

 

  100%{
    transform: translateX(0) ;

  }
  
  
}

@keyframes goingtwice {

  0%{
    transform: translateY(5px) ;
  }

 

  100%{
    transform: translateY(0) ;

  }
  
  
}

@keyframes bellrotating {

  0%{
    transform: rotate(-2.5deg);
  }

 

  100%{
    transform: rotate(2.5deg);

  }
  
  
}

.going-once-img img{
  width: 100%;
  height: auto;
  object-fit: cover;
}
/* ________________________________ 
Our Get your worth  CSS code end here 
-------------------------- */

@media all and (max-width: 1280px) and (min-width: 1195px) {

  .element-arow-bx {
    top: 8rem;
    left: 15rem;
    width: 10rem;
  }



  .element-arow-bx2 {
    top: 5rem;
    left: 42rem;
    width: 10rem;

  }


  .element-arow-bx3 {
    top: 50%;
    left: 95%;
    transform: translateY(-50%);
    width: 5rem;

  }

  .element-arow-bx4 {
    bottom: 9rem;
    left: 45rem;
    width: 10rem;
  }

  .element-arow-bx5 {
    bottom: 9rem;
    left: 17rem;
    width: 10rem;
  }




}


@media all and (max-width: 1195px) and (min-width: 1024px) {

  .element-arow-bx {
    top: 8rem;
    left: 14rem;
    width: 8rem;
  }



  .element-arow-bx2 {
    top: 5rem;
    left: 38rem;
    width: 8rem;

  }


  .element-arow-bx3 {
    top: 50%;
    left: 95%;
    transform: translateY(-50%);
    width: 4.5rem;

  }

  .element-arow-bx4 {
    bottom: 9rem;
    left: 40rem;
    width: 8rem;
  }

  .element-arow-bx5 {
    bottom: 9rem;
    left: 16rem;
    width: 8rem;
  }




}

input , textarea{
  background-color: transparent !important;
}

/* __________________________________
 Our Footer Comp css codee
------------------- */

.footer-comp{
  position: relative;
  width: 100%;
  background-color: #1d212d;
  padding-block-start: 3rem;
}


.footer-cont{
  max-width:87rem;
  margin: 0 auto;
}

.foter-grid-main{
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 3rem;
  row-gap: 3rem;
  padding-bottom: 4rem;
  padding-inline: 1rem;

}

.fotr-line{
  position: relative;
  width: 100%;
  border-bottom: .06rem solid #EFEFE5A6;

}

.left-footer-bx{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;
}

.foter-logo-img-bx{
  position: relative;
  width: 12rem;
}
.foter-logo-img-bx img{
  width: 100%;
  height: auto;
}

.left-footer-bx p{
  color: #c5c3c3;
  letter-spacing: .55px;
  font-size: .92rem;
}

.right-footer-main{
  display: grid;
  grid-template-columns: .6fr 2fr ;
  column-gap: 2.5rem;
  row-gap: 2.5rem;
  margin-left: 5rem;
}

.foter-list-bx{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .65rem;
  align-items: flex-start;
  text-align: left;
  
}

.foter-list-bx button{
  font-size: 1.1rem;
  color: #f6f3f3;
  letter-spacing: .67px;
}

.foter-grid-two-bx{
  display: grid;
  grid-template-columns: 1fr .9fr;
  width: 100%;
  column-gap: 2rem;
  row-gap: 2rem;
}



.fotr-addrs-bx span{
  font-size: .87rem;
  color: #d8d8d8;
  letter-spacing: .65px;
  display: block;
  margin-bottom: 1rem;
}

.fotr-addrs-bx h6{
  font-size: 1.3rem;
  letter-spacing: .65px;
  color: #f6f3f3;
  margin-bottom: 2rem;
}


.fotr-addrs-bx p{
  font-size: .9rem;
  letter-spacing: .65px;
  color: #bcb9b9;
  max-width: 17rem;
}

.fotr-cont-bx h6{
  font-size: 1rem;

}

.fotr-last-text-flex-bx{
  position: relative;
  padding-block: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fotr-last-text-flex-bx span{
  font-size: .9rem;
  color: #a8a5a5;
}

.fotr-last-text-flex-bx p{
  font-size: .87rem;
  color: #c6c6c6;
}

.policy-flex-bx{
  display: flex;
  align-items: center;
  gap: 1.3rem;
  justify-content: flex-start;
}

.policy-flex-bx a{
  font-size: .9rem;
  color: #a8a5a5;
  text-decoration: none;
}

/* _________________________
 Puzzle img css codes
 ---------- */

 .puzle-img-flex{
  position: relative;
  display: flex;
  align-items: center;
 }

 .puzle-img-bx-abs{
  position: absolute !important;
  top: -2rem  !important;
 }

 .puzl-img{
  transition: all 0.5s cubic-bezier(0.45, 0, 0.55, 1);
 }



 .puzl-img1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-110% , -17%);
  width: 12rem;
  z-index: 10;
 }

 .puzl-img2{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-54% , -78%);
  width: 12rem;
  z-index: 5;
 }

 .puzl-img3{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(15% , -20%);
  width: 12rem;
  z-index: 15;
 }

 .puzl-img4{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-43% , 40%);
  width: 12rem;
  z-index: 17;
 }

 .puzl-img1 img{
  width: 100%;
  height: auto;
 }

 .puzle-img-flex:hover .puzl-img1{
  transform: translate(-150% , -17%) rotate(-20deg);
 }

 .puzle-img-flex:hover .puzl-img2{
  transform: translate(-54% , -120%) rotate(-20deg);
 }

 .puzle-img-flex:hover .puzl-img3{
  transform: translate(50% , -20%) rotate(-20deg);
 }

 
 .puzle-img-flex:hover .puzl-img4{
  transform: translate(-43% , 70%) rotate(-20deg);
 }

 /* _____________________________________________
 Our Nobel Prize CSS code
 -------------------------------- */

 .nobel-auction-comp{
  position: relative;
  width: 100%;
  padding-block: 3rem;
  padding-inline: 1.87rem;
  background-color: #efefef;

 }

 .nobel-prize-grid-main-bx{
  position: relative;
  width: 100%;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  background-color: #fff;

 }

 .left-nobel-prize-bx{
  position: relative;
  width: 100%;
  padding-block-start: 3rem;
  padding-block-end: 3rem;
 }

 

 .nobel-img-bx{
  position: relative;
  width: 55%;
  margin: 0 auto 1rem auto;
 }

 .nobel-img-bx img{
  width: 100%;
  height: auto;
 }

 .nobel-prize-info-bx{
  position: relative;
  width: 80%;
  margin: 0 auto;
  background-color: #5300CC;
  color: #fff;
  padding: 2rem 1.5rem;
 }

 .nobel-prize-info-bx h6{
  position: relative;
  text-align: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: .67rem;
  letter-spacing: .76px;
  margin-bottom: 1rem;
 }

 
 .nobel-prize-info-bx h6:before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: .05rem;
  background-color: #e7e6e69e;
 }

 .nobel-prize-info-bx p{
  position: relative;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  letter-spacing: .65px;
  font-weight: 400;
  z-index: 4;

 }

 .right-nobel-info-det-bx{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-align: left;
 }

 .right-nobel-info-det-bx h4{
  font-size: 1.87rem;
  font-weight: 600;
  color: #1d212d;
  letter-spacing: .45px;
  margin-bottom: 1.45rem;

 }

 .right-nobel-info-det-bx p{
  font-size: 1.1rem;
  letter-spacing: .55px;
  max-width: 32rem;
  margin-bottom: 1.5rem;
 }

 .nobel-prize-info-bx p:before{
  content: "";
  position: absolute;
  top: 0;
  left: -3.78rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url('../public/images/quote-up-1.png')no-repeat center center/cover;
  z-index: 1;
 }

 .nobel-prize-info-bx p:after{
  content: "";
  position: absolute;
  bottom: 0;
  right: -3.78rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url('../public/images/quote-up.svg')no-repeat center center/cover;
  z-index: 1;
 }

 .dropdwnmain , .dropdwn-bx{
  position: relative;
 }

 .prod-dropdwn{
  width:max-content;
 }

 .dropdwn-bx{
  position: absolute;
  top: 2.43rem;
  left: 0;
  width: 10rem;
  border-radius: .3rem;
  background-color: #fff;
  transition: 400ms ease;
  opacity: 0;
  transform: translateY(15px);
  visibility: hidden;
  z-index: 99;
 }

 .prod-link-btn.productacitve{
  color: #b5b5b5 !important;
 }

 .dropdwn-bx li{
  position: relative;
  width: 100%;
  padding: .66rem .87rem;
  list-style: none;
  transition: 400ms ease;
  cursor: pointer;

 }

 .dropdwn-bx li a{
  position: relative;
  width: 100%;
  display: inline-block;
  font-size: .9rem;
  text-decoration: none;
  transition: 400ms ease;
  color: #100f0f;
  font-weight: 500;
  letter-spacing: .55px;
 }

 .dropdwn-bx li:hover{
  background-color: #EFEFEF;
 }

 .dropdwn-bx li:hover a{
  color: #5300CC;
 }

 
 .dropdwn-btn{
  z-index: 6 !important;
  background-color: transparent !important;

 }

 .dropdwn-btn.linkactive{
  color: #5300CC !important;
 }

 .dropdwnmain:hover .dropdwn-bx{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
 }

 .drpdwn-li.drpdwnactive{

  background-color: #EFEFEF;
 }
 
 .drpdwn-li.drpdwnactive a{
  color: #5300CC;
 }

 .auction-secnd-comp{
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 9;
  display: flex;
  align-items: center;
 }
 .auction-secnd-comp:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/images/auction-history.png')no-repeat center center/cover;
  z-index: -1;
 }

 .auctions-detls-info-bx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50%;
  background-color: #fff;
  margin-left: auto;
  padding: 60px 60px;
  z-index: 2;
 }


 .auctions-detls-info-bx:before{
  content: "";
  position: absolute;
  top: -4.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 8.5rem;
  height: 8.5rem;
  background: url('../public/images/quote-up.png')no-repeat center center/cover;
  z-index: 1;
 }

 .auctions-detls-info-bx p{
  font-size: 1.1rem;
  margin-bottom: 1rem;
 }

 .mob-dropdwn-bx{
  display: none;
 }

 .dropdwnmain li{
  list-style: none;
 }

 .auctions-detls-info-bx h6{
  font-size: 1.3rem;
  font-weight: 600;
  color: #3b3a3a;
  margin-bottom: 1rem;
  line-height: 1.5;

 }

 /* _____________________- 
 Our team card css code
 -------------- */

 .team-main-bx{
  position: relative;
  overflow: hidden;
 }

 .team-hover-bx{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5300CC;
  border-radius: .55rem;
  transition: all 0.4s cubic-bezier(0.5, 1, 0.89, 1);
  transform: translateY(50%);
  opacity: 0;
  visibility: hidden;
  padding: .5rem .5rem;
 }

 
 .team-main-bx:hover .team-hover-bx{
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
 }

 .team-first-flex-bx{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
 }

 .left-team-img {
  position: relative;
  width: 120px;
  height: 120px;
 }


 .left-team-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
 }


 .right-team-info{
    margin: 0 0;
    padding: 0 0;
 }
 
 .right-team-info h6{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .3px;
  margin-bottom: .65rem;
 }

 .right-team-info p{
  color:rgba(255, 255, 255, 0.90) ;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: .4px;
 }

 .team-para{
  position: absolute;
bottom: 1rem;
left: 2rem;
width: 100%;
font-size: 14px;
font-weight: 500;
color: #fff;
max-width: 80%;
margin: 0 auto;
padding-inline-end: 2rem;
 }

 .team-icon{
  position: absolute;
  bottom:2rem;
  right: 1rem;
  font-size: 25px;
  color: #fff;
 }

 .team-text-lgt {
  max-width: 15rem;
 }

 .right-team-info2 p{
  max-width: 12rem;
  font-size: 13px;
 }


 ::selection{
  background-color: #3d0f74;
  color: #fff;
 }

 .fotr-last-text-flex-bx{
  padding-inline: 1rem;
 }

 .dexa-token-comp .last-center-text{
  margin-top: 2rem;
 }

 .mob-img-bx{
  display: none;
 }

  /* ________________________________
 Product Page Components CSS code Starts here (date : 09-05-2024)
 ---------------------------------------- */
 
 .reimagning-comp{
  position: relative;
  width: 100%;
  padding: 5rem 1rem;
  background-color: #fff;
  z-index: 9;
 }



 .reimag-img-main-bx{
  position: relative;
  width: 100%;
  padding-block: 5rem;
 }
 
 .reimag-img-bx{
  position: relative;
  width: 94%;
  height: auto;
  margin: 0 auto;
 }

 .reimag-img-main-bx:before{
  content: "";
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  background: url('../public/productimages/hero-bg-img.png')no-repeat center center/cover;
  z-index: -1;
 }

 .reimag-para-cent{
  position: relative;
  max-width: 40.5rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;
  color: #110F42;
  font-weight: 400;
 }

 /* ______________________ IRO Comp css code
 --------------------------------------------- */

 .iro-comp{
  position: relative;
  width: 100%;
  padding: 5rem 1rem;
  background-color: #5300cc;
 }
 
 .iro-content-bx{
  margin-top: 3rem;
 }

 .iro-content-bx h3 ,
 .iro-content-bx p{
  color: #fff;
 }

 .iro-grid-bx{
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 5rem;
 
 }

 .left-iro-img{
  position: relative;
 width: 75%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .left-iro-img img{
  width: 100%;
  height: auto;
  object-fit: cover;
 }

 .right-iro-content-flex-bx{
  position: relative;
  width: 80%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
 }

 .full-width-iro-content-bx{
  position: relative;
  width: 100%;
  padding: 2rem 2rem;
  background-color: #8850EB;
  border-radius: .45rem;
  
 }

 .full-width-iro-content-bx p{
  color: #fff;
 }

 .iro-mini-bx{
  position: relative;
  width: 100%;
  height: 14rem;
  background-color: #8850EB;
  border-radius: .45rem;
  padding: 1.5rem 1.5rem;

 }

 .iro-grid-bx2{
  margin-top: 1rem;
  column-gap: 1rem;
  row-gap: 1rem;
 }

 .iro-mini-img{
  position: absolute;
  top: 0;
  right: 0;
  width: 11.5rem;
  z-index: 2;
 }

 .iro-mini-img img{
  width: 100%;
  height: auto;
 }

 .iro-mini-bx h5{
  position: absolute;
  bottom: 2rem;
  color: #fff;
  font-weight: 500;
  letter-spacing: .5px;
  z-index: 4;
 }

 .iro-mini-img2{
  top: 100% ;
  transform: translateY(-100%);
  right: 0;
 }

 .iro-mini-bx2 h5{
  top: 2rem;
 }

 .iro-grid-bx-main{
  z-index: 5;
 }

 .iro-grid-bx-main:before{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  width: 90%;
  height: 100%;
  background: url('../public/images/rndmainbg.png')no-repeat center center/cover;
  z-index: -1;
 }

 /* __________________________ Our Rdex Comp CSS  */

 .rdex-comp{
  position: relative;
  width: 100%;
  padding: 5rem 0;
  background-color: #efefef;
 }

 .rdex-grid:before{
  display: none;
 }

 .rdex-round-text{
  padding-inline: 1rem;

 }

 .rdex-round-text button{
  color: #5300CC;
  border-color: #5300CC;
 }

 .rdex-content-bx{
  margin-top: 2rem;
  padding-inline: 1rem;
 }

 .left-rdex-content{
  width: 90%;
  margin-left: 0;
 }

 .left-rdex-content .full-width-iro-content-bx , 
 .rdex-mini-bx{
  background-color: #fff;
 }

 .left-rdex-content .full-width-iro-content-bx p , 
 .rdex-mini-bx h5{
  color: #100f0f;

 }

 .right-rdex-img-bx{
  position: relative;
  width: 90%;
  margin-left: auto;
 }

 .right-rdex-img-bx img{
  width: 100%;
  height: auto;
 }

 .left-deep-img{
  width: 80%;
 }

 .right-deep-content-flex-bx{
  width: 95%;
 }

 .right-deep-content-flex-bx .full-width-iro-content-bx{
  padding-inline-end: 3rem;
 }



 .slider-container{
  position: relative;
  width: 100%;
  height: 10rem;
  margin-top: 4rem;

 }



 .net-suport-bx{
  position: relative;
  width: 10rem;
  height: 10rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin: 0 auto;
 }

 .net-suport-bx img{
  width: 4.5rem;
  height: auto;

 }

 

 .slider-container .react-multi-carousel-dot-list{
  bottom: -3px !important;
 }
 
 .slider-container .react-multi-carousel-dot button{
  border-color: #b8b8b8 !important;
  background: #b8b8b8 !important;

 }
 .slider-container .react-multi-carousel-dot button:hover:active ,
 .slider-container  .react-multi-carousel-dot--active button{
  border-color: #5300C9 !important;
  background: #5300C9 !important;
 }

 .slider-container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right , 
 .slider-container .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    display: none !important;
 }

 .slider-container .react-multi-carousel-list{
  height: 12rem !important;
  align-items: flex-start !important;
 }

 .react-multiple-carousel__arrow--left , 
 .react-multiple-carousel__arrow--right{
  top: 50% !important;
  transform: translateY(-50%) !important;
 }

 .react-multiple-carousel__arrow--left{
  left: calc(4% + 1px) !important;
 }
 .react-multiple-carousel__arrow--right{
  right: calc(4% + 1px) !important;
 }

 /* __________________________________ 
 OUr Infrastructure Comp CSS
 --------------------------------- */

 /* .infrastructure-comp{
  position: relative;
  width: 100%;
  background-color: #fff;
  padding-block-start: 3rem;
  padding-block-end: 5rem;
  padding-inline: 1rem;
 }

 .infra-cont-bx h3{
  max-width: 50rem;
 } */


 .infrastructure-comp{
  position: relative;
  width: 100%;
  padding-block-start: 3rem;
  padding-block-end: 5rem;
  padding-inline: 1rem;
  background-color: #fff;
 }

 .infrastr-cont-bx h3{
  max-width: 50rem;
 }

 .infrastrutre-grid-main-bx{
  position: relative;
  width: 97%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 3rem;
 }

 .lin-dot{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24.35%;
  height: 95%;
  width: 1.2px;
  background: url('../public/images/dash-line-img.png') center center/cover;
  z-index: 9;
 }
 .lin-dot2{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 49.9%;
  height: 95%;
  width: 1.5px;
  background: url('../public/images/dash-line-img.png') center center/cover;
  z-index: 9;
 }

 .lin-dot3{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 75.5%;
  height: 95%;
  width: 1.2px;
  background: url('../public/images/dash-line-img.png') center center/cover;
  z-index: 9;
 }

 .dexa-infra-main-bx{
  position: relative;
  width: 100%;

 }

 .dexa-infra-bx{
  position: relative;
  width: 100%;
  height: 13.5rem;
  border: .1rem solid #ddd;
  border-radius: .4rem;
  padding-inline: 1.5rem;
  padding-block: 1rem;

 }

 .dexa-infra-bx p{
  text-align: center;
  font-size: 1.05rem;
  font-weight: 600;
  color: #5300C9;
  max-width: 10rem;
  margin: 0 auto;
  height: 8rem;

 }

 .dexa-infra-bx p{
  max-width: 12rem ;
 }
 
 
 .dexa-infra-bx button{
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: 3rem;
  /* border: .1rem dashed #5300C9; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .87rem;
  font-weight: 700;
  letter-spacing: .3px;
  color: #5300C9;
  background: url('../public/images/buton-dash-border-img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: 100%;
  background-position-y: 100%;
  line-height: 2;
 }



 .dexa-data-infra-bx{
  position: relative;
  width: 100%;
  background-color: #f2f2f2;
  padding: .65rem .65rem;
  border-radius: .65rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  row-gap: 2rem;
  margin-top: 5rem; 

 }

 .dexa-data-infra-main{
  position: relative;
  width: 100%;
 }

 .dexa-data-info-bx{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

 }

 .dexa-data-info-bx img{
  width: 5rem;
  height: auto;
  margin-bottom: 1rem;
 }

 .dexa-data-info-bx h6{
  font-size: 1.05rem;
  font-weight: 700;
  color: #464649;
  text-align: center;
 }

 .block-chain-bx{
  position: absolute;
  top: 41.5%;
  transform: translateY(-50%);
  width: 100%;
  height: 3.87rem;
  background-color: #5300C9;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .4px;
  border-radius: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;


 }

 .block-chain-bx2{
  position: relative;
  top: 0;
  left: 0;
  transform: translateY(0);
  width: 95%;
  margin: 2rem auto 0 auto;
  background-color: transparent;
  color: #5300C9;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: .1rem  dashed #5300C9; */
  /* border-width: 2px; */

 }

 


 .block-chain-bx2:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/images/big-buton-border-img.png');
  background-repeat: no-repeat;
    background-size: 100% 100%;
  background-position: 100% 50%;
  z-index: -1;
 }
 
 .prod-link-btn.productacitve{
  color: #5300C9 !important;
 }

 

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 /*  Our Media queries  */

 @media all and (min-width : 1230px) and (max-width: 1300px){

  .going-once-img2 , .going-once-img1{
    left: 0 !important;
  }

  .going-once-img3{
    bottom: 2rem;
    right: -9rem;
  }

 }

 @media all and (min-width : 1024px) and (max-width: 1230px){

  .net-suport-bx{
    width: 8rem;
    height: 8rem;
  }

  /* ____________ Our IRO Component responsive */

  .iro-grid-bx-main{
  }

  .right-iro-content-flex-bx{
    width: 95%;
  }

  .going-once-img2 , .going-once-img1{
    left: -6rem !important;
  }

  .going-once-img3{
    bottom: 2rem;
    right: -9rem;
  }

  .nobel-prize-info-bx p{
    font-size: .87rem;
    max-width: 80%;

  }



  .auctions-detls-info-bx{
    padding: 30px 30px;
  }

  .auctions-detls-info-bx:before{
    top: -5rem;
    width: 7rem;
    height: 7rem;
  }

  .right-why-dexauction-title-bx h3{
    text-align: right;
    font-size: 2.87rem;
  }

  .why-dexauction-img{
    width: 7rem;
  }

  .why-dexauction-info h5{
    font-size: 1.1rem;
  }

  .right-why-dexauction-title-bx{
    max-width: 45rem;
  }

  .text-\[\#5300CC\]{
    font-size: 1rem;
  }

  .why-dexauction-info p{
    font-size: .87rem;
  }

  .why-dexauction-bx{
    padding: 2rem 2rem;
  }
  .soc-links-flex{
    flex-wrap: wrap;
    gap: .65rem;
  }

  .w-\[40px\]{
    width: 20px;
  }


  
 }



 @media all and (min-width : 767px) and (max-width: 1024px){

  /* ________________________________________ */

  .block-chain-bx2:before{
    background-size: 100% 100%;
  } 

  .dexa-infra-bx button{
    width: 82%;
  }

  .lin-dot , .lin-dot2 , .lin-dot3{
    display: none;
  }

  .dexa-data-info-bx img{
    width: 3.2rem;
  }

  .dexa-data-infra-bx{
    column-gap: 0;
    row-gap: 1rem;
  }

  .infrastrutre-grid-main-bx{
    column-gap: .65rem;
  }

  .dexa-data-infra-bx{
    margin-top: 4rem;
  }

  .block-chain-bx{
    top: 44.5%;
  }

  .infrastrutre-grid-main-bx{
    width: 100%;
  }

  .dexa-data-info-bx h6{
    font-size: .87rem;
  }

  .dexa-infra-bx{
    height: 12rem;
  }

  .net-suport-bx{
    width: 7.5rem;
    height: 7.5rem;
  }

  .dexa-infra-bx p{
    font-size: .95rem;
    height: 6.5rem;
  }

  .dexa-infra-bx{
    padding-inline: 1rem;
  }

  /* ________________________- Rdex Comp responsive */

  .right-rdex-img-bx{
    width: 70%;
    margin: 0 auto;
  }

    /* ____________ Our IRO Component responsive */

    .iro-grid-bx-main{
      grid-template-columns: repeat(1, 1fr);
      row-gap: 5rem;
    }
  
    .right-iro-content-flex-bx{
      width: 90%;
      margin: 0 auto;
    }

    .left-iro-img{
      width: 60%;
      margin: 0 auto;
    }


  /* Product page responsive code starts here 
  -------------------------------------------- */

  .reimag-content-bx{
    justify-content: center;
    align-items: center !important;
    text-align: center;
  }

    /* Product page responsive code end here 
  -------------------------------------------- */


   .going-once-img1{
    left: -6rem !important;
  }

  .going-once-img2{
      left: -8rem;
  }

  .going-once-img1{
    width: 32rem;
  }

  .going-once-img2{
    width: 35rem;
  }

  

  .going-once-img3{
    width: 13rem;
    bottom: 1rem;
    right: -5rem;
  }

    .main-parent-flex{
      display: flex !important;
      flex-direction: column !important;
  }

  .hw-it-wrk-flex-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.5rem;
    max-width: 45rem !important;
  }

  .md\:order-2{
    order: 0 !important;
  }

  .left-hw-it-wrk-bx{
    width: 250px;
    height: 350px;
  }

  .hiw-comn-bx{
    width: 100%;
  }

 .right-hiw-main-flex{
  width: 290px;
 }

 .center-hiw-round-bx{
  width: 155px;
  height: 155px;
 }

 .center-hiw-round-bx img{
  width: 3rem;
 }

 .center-hiw-round-bx h6 , .center-hiw-bx p{
  font-size: .87rem;
  line-height: 1.3;
 }

 .element-arow-bx{
  top: 7rem;
  left: 10rem;
  width: 5rem;
 }

 .element-arow-bx2{
  top: 5rem;
  left: 26rem;
  width: 5rem;
 }

 .element-arow-bx5{
  bottom: 7rem;
  left: 11rem;
  width: 5rem;
 }

 .element-arow-bx4{
  bottom: 6rem;
    left: 27rem ;

    width: 5rem;
 }

 .element-arow-bx3{
  width: 2.5rem;
 }

 .hiw-comn-bx{
  padding: 1rem 1rem;
 }

 .hiw-comn-bx p{
  font-size: 13px;
 }

 .hiw-comn-main h6{
  font-size: .87rem;
  line-height: 1.3;
 }

 .left-hw-it-wrk-bx img{
  object-fit: contain;
 }

  .space-top{
    margin-top: 15rem;
    align-items: flex-start;
  }

  .space-bottom{
    margin-bottom: 10rem;
  }

  .md\:w-auto{
    width: 100% !important;
  }

  .md\:gap-20{
    gap: 2rem !important;
  }

  .global-market-size-info-flex-bx{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .sale-volume-main-bx{
    margin-top: 5rem;
  }

  .sale-valume-text-bx{
    margin-bottom: 15rem;
  }

  .globe-img{
    top: 53%;
  }

  .sales-volume-bx p{
    font-size: .87rem;
  }

  .sales-volume-bx h5{
    font-size: 2.5rem;
  }

  .sales-volume-bx{
    padding: 1rem 1rem;
  }

  .nobel-prize-info-bx p:after {
    right: -3.5rem !important;
  }

  .nobel-prize-info-bx p:before{
    left: -3rem !important;
  }

  .right-nobel-info-det-bx h4{
    font-size: 1.7rem;
  }

  .right-footer-main{
    grid-template-columns: 1fr;
  }

  .auctions-detls-info-bx{
    padding: 30px 30px;
  }

  .auctions-detls-info-bx p{
    font-size: 1rem;
  }

  .auctions-detls-info-bx h6{
    font-size: 1.1rem;
  }

  .auctions-detls-info-bx:before{
    top: -3.55rem;
    width: 5.5rem;
    height: 5.5rem;
  }

  .why-dex-auction-flex-top-flex-bx{
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }


  .right-why-dexauction-title-bx h3{
    text-align: left;
    font-size: 2.5rem;
  }

  .why-dexauction-img{
    width: 7rem;
  }

  .why-dexauction-info h5{
    font-size: 1.1rem;
  }

  .right-why-dexauction-title-bx{
    max-width: 45rem;
  }

  .text-\[\#5300CC\]{
    font-size: .87rem;
  }

  .why-dexauction-cards-grid{
    grid-template-columns: repeat(2, 1fr);
  }

  .why-dexauction-info p{
    font-size: .87rem;
  }

  .why-dexauction-bx{
    padding: 2rem 2rem;
  }

  .soc-links-flex{
    flex-wrap: wrap;
    gap: .65rem;
  }

  .w-\[40px\]{
    width: 20px;
  }

  .global-market-size-info-flex-bx h3{
    font-size: 2.5rem;
    max-width: 100%;
  }

  .global-market-size-info-flex-bx p{
    font-size: 1rem;
  }

  .dexa-token-comp .last-center-text{
    font-size: 1rem;
    max-width: 35rem;
  }
  
 }

.why-des-mob , .mob-view{
  display: none !important;
}
 
 @media all and (min-width : 320px) and (max-width: 767px){
  
  /* ________________________________________ */
  .lin-dot , .lin-dot2 , .lin-dot3{
    display: none;
  }

 

  .block-chain-bx2:before{
    background-size: 100% 100%;
  } 

  .dexa-data-info-bx img{
    width: 3.2rem;
  }

  .dexa-data-infra-bx{
    column-gap: 0;
    row-gap: 1rem;
  }

  .infrastrutre-grid-main-bx{
    column-gap: .65rem;
    grid-template-columns: repeat(1, 1fr);
  }


  .dexa-data-infra-bx{
    margin-top: 4rem;
  }

  

  .infrastrutre-grid-main-bx{
    width: 100%;
  }

  .dexa-data-info-bx h6{
    font-size: .87rem;
  }

  .dexa-infra-bx{
    height: 12rem;
  }

  .net-suport-bx{
    width: 7.5rem;
    height: 7.5rem;
  }

  .dexa-infra-bx p{
    font-size: .95rem;
    height: 6rem;
  }

  .dexa-infra-bx{
    padding-inline: 1rem;
  }

.net-suport-bx{
  width: 8rem;
  height: 8rem;
}

.net-suport-bx img{
  width: 5.5rem;
}

.dexa-data-infra-bx{
  margin-top: 0;
}

.block-chain-bx2{
  margin-top: 7rem;
}

.block-chain-bx{
  top: 103%;
  font-size: .9rem;
}


 /* ________________________- Rdex Comp responsive */

 .right-rdex-img-bx{
  width: 100%;
  margin: 0 auto;
}

  /* ____________ Our IRO Component responsive */

  .iro-grid-bx-main{
    grid-template-columns: repeat(1, 1fr);
    row-gap: 5rem;
  }

  .iro-grid-bx-main:before{
    display: none;
  }

  .right-iro-content-flex-bx{
    width: 100%;
    margin: 0 auto;
  }

  .left-iro-img{
    width: 20rem;
    margin: 0 auto;
  }

  .iro-grid-bx2{
    grid-template-columns: repeat(1, 1fr);
  }

/* Product page responsive code starts here 
-------------------------------------------- */

.reimag-img-main-bx{
  padding-block: 3rem;
}

.reimag-img-main-bx:before{
  top: 50%;
}

.reimag-img-bx{
  width: 100%;
}

.reimagning-comp{
  padding: 3rem 1rem 4rem 1rem;
}

  /* Product page responsive code end here 
-------------------------------------------- */


.how-it-work-comp{
  display: none;
}

.token-comp-info{
  margin-top: -8rem !important;
}

.token-comp{
  padding-block-end: 4rem !important;
}
 

.globe-img{
  top: 7% !important;
}

.why-des-desktop , .desktop-view{
  display: none !important;
}

.why-des-mob , .mob-view{
  display: block !important;
}

.why-dexauction-bx:hover{
  transform: translateY(0) !important;
}

  .auctions-detls-info-bx{
    padding: 24px 24px;
  }
  .text-sml{
    width: max-content;
  }
 .text-sml span{
  font-size: 1.5rem !important;
  display: inline-block !important;
  width: max-content !important;
 }

  .auctions-detls-info-bx p{
    font-size: .78rem;
  }

  .auctions-detls-info-bx h6{
    font-size: 1rem;
  }

  .auctions-detls-info-bx:before{
    top: -3.2rem;
    width: 5rem;
    height: 5rem;
  }

  .auction-secnd-comp:before{
    height: 45%;
  }

  .auctions-detls-info-bx{
    position: relative !important;
    top: 0 !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(0) !important;
    margin-left: 0% !important;
    width: 20rem !important;
    margin-top: 18rem;

  }

  .market-size-comp{
    margin-top: 10rem;
  }

  .global-market-size-info-flex-bx{
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  
  .global-market-size-info-flex-bx h3{
    font-size: 2.2rem;
    max-width: 100%;
  }

  .global-market-size-info-flex-bx p{
    font-size: 1rem;
  }

  .dexa-token-comp .last-center-text{
    font-size: 1rem;
    max-width: 35rem;
  }

  .soc-links-flex{
    flex-wrap: wrap;
    gap: .65rem;
  }

  .w-\[40px\]{
    width: 20px;
  }
  .foter-grid-two-bx{
    grid-template-columns: repeat(1, 1fr);
  }

  
  .right-footer-main{
    margin-left: 0;
    grid-template-columns: 1fr;
  }

  .foter-grid-main{
    grid-template-columns: repeat(1, 1fr);
  }


  .why-dex-auction-comp{
    padding: 2.5rem 1rem;
  }

  .why-dex-auction-flex-top-flex-bx{
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }


  .right-why-dexauction-title-bx h3{
    text-align: left;
    font-size: 2.4rem;
  }

  .why-dexauction-img{
    width: 7rem;
  }

  .why-dexauction-info h5{
    font-size: 1.1rem;
  }

  .right-why-dexauction-title-bx{
    max-width: 45rem;
  }

  .text-\[\#5300CC\]{
    font-size: .87rem;
  }

  .why-dexauction-cards-grid{
    grid-template-columns: repeat(1, 1fr);
  }

  .why-dexauction-info p{
    font-size: .87rem;
  }

  .why-dexauction-bx{
    padding: 2rem 2rem;
  }

  .nobel-prize-info-bx p{
    font-size: .76rem;
  }

  .nobel-prize-info-bx h6{
    font-size: .87rem;
    letter-spacing: .45px;
  }

  .fotr-last-text-flex-bx{
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: .5rem;
  }

  .text-img-bx-main{
    width: 100%;
  }

  .text-img-bx1{
    left: -0.5rem !important;
  }

  .text-img-bx2{
    left: -1rem !important;
    width: 36rem !important;
  }

  .text-img-bx-main{
    padding-block: 4rem;
  }

  .text-img-bx-main:before{
    left: 20%;
  }

  .text-img-bx3{
    top: .5rem !important;
    width: 39rem !important;
  }

  .text-img-bx{
    width: 30rem;
  }

  .nobel-prize-info-bx p:after {
    right: -3.5rem !important;
  }

  .nobel-prize-info-bx p:before{
    left: -3rem !important;
  }

  .right-nobel-info-det-bx h4{
    font-size: 1.7rem;
  }

  .nobel-prize-grid-main-bx{
    grid-template-columns: repeat(1, 1fr);
  }

  .right-nobel-info-det-bx{
    width: 90%;
    align-items: center;
    margin: 0 auto;
  }

  .nobel-auction-comp{
    padding-inline: 1rem;
  }

  .nobel-prize-info-bx{
    width: 90%;
    
  }

  .global-market-size-info-flex-bx{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .sale-volume-main-bx{
    margin-top: 3rem;
  }

  .sale-valume-text-bx{
    margin-bottom: 10rem;
  }

  .globe-img{
    top: 14%;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    width: 323px;
    height: 305px;
    object-fit: cover;
  }

  .sales-volume-bx p{
    font-size: .87rem;
  }


  .token-check-bg:before{
    height: 100% !important;
  }
 

  .sales-volume-bx h5{
    font-size: 2.5rem;
  }

  .sales-volume-bx{
    padding: 1rem 1rem;
  }

  .sales-volme-grid{
    grid-template-columns: 1fr;
  }
  .market-size-comp{
    padding: 3rem 1rem !important; 
  }

  .token-img2{
    width: 100%;
    padding: 1rem 0 3rem 0;
  }

  .puzl-img{
    width: 8rem;
  }

  .space-bottom{
    margin-bottom: 6rem;
  }

  .space-top{
    margin-top: 10rem;
  }

  .left-hw-it-wrk-bx{
    width: 100% !important;
    height: 500px !important ;
  margin-top: 3rem !important;

  }

  .hiw-comn-bx{
    width: 100%;
  }

 .right-hiw-main-flex{
  width: 100%;
  padding: 1.5rem 1.5rem;
  align-items: center;
 }

 .center-hiw-round-bx{
  width: 160px;
  height: 160px;
 }

 .center-hiw-round-bx img{
  width: 3.5rem;
 }

 .center-hiw-round-bx h6 , .center-hiw-bx p{
  font-size: .87rem;
  line-height: 1.3;
 }

 .hiw-comn-main h6{
  font-size: 1rem !important;
  line-height: 1.5 !important;
 }

 .hiw-comn-img{
  width: 4rem;
 }

 .element-arow-bx{
  top: 7rem;
  left: 10rem;
  display: none;
  width: 5rem;
 }

 .element-arow-bx2{
  top: 5rem;
  left: 26rem;
  width: 5rem;
  display: none;

 }

 .element-arow-bx5{
  bottom: 7rem;
  left: 11rem;
  width: 5rem;
  display: none;

 }

 .element-arow-bx4{
  bottom: 6rem;
    left: 27rem ;

    width: 5rem;
    display: none;
 }

 .element-arow-bx3{
  width: 2.5rem;
  display:  none;
 }

 .hw-it-wrk-flex-main{
  gap: 2rem;
 }

 .center-hiw-flex-bx{
  height: 440px !important;
 }

 .how-it-work-comp{
  padding: 3rem 1rem;
 }

 .hiw-comn-bx p{
  font-size: 10px !important ;
  max-width: 100%;
 }
  

 .hiw-comn-bx{
  padding: .87rem .87rem !important;
 }

 .left-hw-it-wrk-bx img{
  object-fit: contain;
 }

 .hw-it-wrk-flex-main{
  flex-direction: column;

 }

 .text-img-bx , .going-once-img{
  display: none;
 }

 .mob-img-bx{
  display: block !important;
 }

 .token-img{
  width: 100%;
  padding: 2rem 0 3rem 0;
 }

 .dropdwn-bx{
  display: none;
 }

 .nav{
  
perspective: 800px;

 }

 .navbar-links-flex{

  position: absolute;
  top: 3.1rem;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: block !important;
  border-bottom: .05rem solid #ddd;
  transform-origin: top;
  transform: rotateX(-70deg) ;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
transition:  all 0.87s cubic-bezier(0.37, 0.1, 0.63, 1.5);
z-index: 99;
}

.navbar-links-flex.navlinkmobileactive{
  transform: rotateX(0);
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.navbar-links-flex > div{
  display: block ;

}

  .mt-\[20\%\] {
  margin-top: 0 !important;
    
  }



  .dropdwnmain{
    position: relative;
    width: 100%;
    padding: .67rem 1rem;
    border-bottom: .1rem solid #eee;
  }

  .dropdwnmain:last-child{
    border-bottom: none;
  }

  .dropdwnmain a{
    position: relative;
    display: inline-block;
    width: 100%;
  }


  .prod-dropdwn a{
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .prod-dropdwn a span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: .2rem .55rem;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .45px;
    color: #5300CC;
    border: .05rem solid #5300CC;
    background-color: transparent;
    border-radius: 3rem;
    opacity: 0;
    visibility: hidden;
    transition: 400ms ease;

  }

  .prod-dropdwn:hover {
    background-color: transparent;
  }

  .prod-dropdwn:hover a{
    color: #5300CC !important;
  }


  .prod-dropdwn:hover a span{
    opacity: 1;
    visibility: visible;
  }

  .mob-dropdwn-bx{
    position: relative;
    width: 100%;
    display: block;
    display: none;
  }

  .mob-dropdwn-bx li{
    position: relative;
    width: 100%;
    list-style: none;
    padding: .55rem 0;
  }

  .mob-dropdwn-bx li a{
    font-size: 14px;
    text-decoration: none;
    color: #100f0f;
    font-weight: 500;
    letter-spacing: .45px;
  }

  .dropdwnmain li{
    list-style: none;
  }

  .comn-son-text{
    display: none;
  }
  
  .dropdwnmain li:hover .mob-dropdwn-bx{
    display: block;
  }

  .dropdwnmain:hover > li > a{
    color: #5300CC !important;
    font-size: 1.2rem !important;
  }

  .mob-bg-vew{
    position: relative;
    z-index: 2;
    background-color: #fff;
  }

  .mob-bg-vew:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../public/images/aisupbg.png')no-repeat center center/cover;
    z-index: -1;
  }

  .token-comp-info h3{
    font-size: 2.7rem;
  }

  .img-grid{
    position: relative;
    width: 100%;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    column-gap: 2rem;
    row-gap: 2rem;
    place-items: center !important;
  
  }

  .mob-view-flex{
    display: flex;
  }

 
 
 }

 .btn-clas{
  position: relative;
  width: max-content;
 }

 .f-d{
  position: relative;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: .5rem;
 }

 .ai-text{
  position: relative;
  width: 25rem !important;
 }


 .prod-link-btn  span{
  display: none;
 }

 .prod-link-bx{
  position: relative;
 }

 .comn-son-text{
  position: absolute;
  top: 2rem;
  left: 50%;
  width: max-content;
  background-color: #fff;
  border: .1rem solid #5300C9;
  padding: .2rem .5rem .3rem .5rem; 
  border-radius: 3rem;
  font-size: .87rem;
  font-weight: 500;
  color: #5300C9;
  transition: 500ms  cubic-bezier(0.61, 1, 0.88, 1);;
  opacity: 0;
  transform: translateY(15px) translateX(-50%);
 }

 .prod-link-bx:hover .comn-son-text{
  opacity: 1;
  transform: translateY(0) translateX(-50%);
 }


 ::-webkit-scrollbar{
  width: .354rem;
  background-color: transparent;
 }

 ::-webkit-scrollbar-thumb{
  background-color: #5300C9 !important;
 }