@keyframes move-right {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    transform: translateX(130px) rotate(180deg);
  }
  100% {
    transform: translateX(0) rotate(360deg);
  }
}

@keyframes move-left {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    /* transform: translateX(-130px) rotate(-180deg); */
    transform: translateY(-130px) rotate(-180deg);
  }
  100% {
    transform: translateX(0) rotate(-360deg);
  }
}

.box-animation-1 {
  animation: move-right 5s ease-in-out infinite;
}

.box-animation-2 {
  animation: move-left 5s ease-in-out infinite;
}
